import styled from '@emotion/styled';
import React, { Component } from 'react'
import Logos from '../../assets/icon/Logos.svg'

const Pre = styled('p')({
    fontWeight: '700',
    fontSize: '48px',
    fontFamily: 'Inter',
    color: '#262626',
    marginTop: 80,
    textAlign: 'center',
    lineHeight:1,
    "@media (max-width: 660px)":{
        fontSize: '32px',
        marginTop: 30,
    }
});
const P = styled('p')({
    fontWeight: '600',
    fontSize: '18px',
    fontFamily: 'Inter',
    color: '#4B4B4B',
    textAlign: 'center',
    lineHeight:1,
    marginBottom:48
});
const Btn = styled("button")({
  fontWeight: "700",
  fontSize: "18px",
  fontFamily: "Inter",
//   color: "#262626",
  textAlign: "center",
  border: "none",
  borderRadius: 16,
  background: "#00B073",
  color: "white",
  padding: 8,
  paddingLeft: 25,
  paddingRight: 25,
  marginBottom: 80,
});
const Div = styled('div')({
   display:'flex',
   alignItems:'center',
   justifyContent:'center',
   marginTop:64,
   "@media (max-width: 660px)":{
    marginTop:30,
}
});
const Img = styled('img')({
    
 });


const Over = () => {
    return (
      <div>
        <div>
            <Pre>Over 100+ <br/> Integrations</Pre>
            <P>Integrate with all the marketing & social apps you use everyday.</P>
        </div>
        <Img src={Logos} width='100%' />
        <Div>
            <Btn>View Integrations</Btn>
        </Div>
      </div>
    )
}

export default Over
