import styled from "@emotion/styled";
import { url } from "inspector";
import React, { Component } from "react";
import Img from "../../assets/icon/security.svg";
import nodesline from "../../assets/icon/nodes-line.svg";
import heroBanner from "../../assets/icon/HeroBanner.png";

const ImgTag = styled("img")({
  marginTop: "12%",
  display: "block",
  maxWidth: "564px",
  height: "451px",
  // position: 'absolute',
  // left: '28%',
  margin: "auto",
  "@media (max-width: 1000px)": {
    marginTop: "5px",
    maxWidth: "400px",
    height: "350px",
    // left: "54%"
  },
  "@media (max-width: 660px)": {
    marginTop: "5px",
    maxWidth: "340px",
    height: "356px",
    // left: "54%"
  },
  
  "@media (max-width: 450px)": {
    marginTop: "5px",
    maxWidth: "360px",
    height: "330px",
    // left: "54%"
  },
  "@media (max-width: 380px)": {
    marginTop: "5px",
    maxWidth: "290px",
    height: "300px",
    // left: "54%"
  },
});
const H1 = styled("h1")({
  fontWeight: "700",
  fontSize: 60,
  fontFamily: "Inter",
  marginBottom: "30px",
  "@media (max-width: 660px)": {
    fontSize: "32px",
  },
});

const P = styled("p")({
  fontWeight: "600",
  fontSize: 22,
  fontFamily: "Inter",
  color: "#4B4B4B",
});
const IconImg = styled("img")({
  height: 28,
  width: 28,
  marginRight: 10,
});
const Div = styled("div")({
  marginBottom: "30px",
  display: "flex",
  "@media (max-width: 660px)": {
    margin: "auto",
    display: "inline-flex",
  },
});
const ImgDiv = styled("div")({
  // backgroundImage: `url(${heroBanner})`,
  backgroundRepeat: "no-repeat",
  maxwidth: "870.45px",
  // height: "765px",
  // position: 'absolute',
  margin: "80px auto 0px auto",
  // left: '32%',
  "@media (max-width: 660px)": {
    width: "auto",
    height: "365px",
    margin: "10px auto 0px auto",
    // position: "absolute",
  },
});

const Main = styled("div")({
  height: "100%",
  "@media (max-width: 660px)": {
    textAlign: "center",
  },
});
const Blue = styled("p")({
  fontWeight: "600",
  fontSize: 16,
  fontFamily: "Inter",
  color: "#5D5FEF",
});

const Container = styled("div")({
  marginTop: "80px",
  "@media (max-width: 660px)": {
    // paddingLeft: "5%",
    marginTop: "40px",
  },
});

const Pre = styled("div")({
  fontWeight: "400",
  fontSize: 18,
  fontFamily: "Inter",
  color: "#4B4B4B",
  marginBottom: "30px",
  width: "98%",
});
const Btn = styled("button")({
  width: 164,
  height: 42,
  background: "#00B073",
  color: "white",
  // background: "linear-gradient(45deg, #5e6bfb 0%, #5e6bfb75 100%)",
  fontWeight: "700",
  fontSize: 18,
  fontFamily: "Inter",
  border: "none",
  borderRadius: "16px",
  marginBottom: "30px",
});

const About = () => {
  return (
    <Main>
      <div className="row" style={{ marginRight: 0 }}>
        <div className="col-sm-12 col-md-6">
          <Container>
            <H1 className="mt-4">
              Make a Trade With <br /> Paraacrypto
            </H1>
            <Div>
              {/* <div className="d-flex"> */}
              <IconImg src={nodesline} />
              <P>First In CEX platform</P>
              {/* </div> */}
            </Div>
            <Pre>
              Our CEX platform offers an easy-to-use, feature-rich environment
              that caters to both new and experienced users in the digital asset
              space.
            </Pre>
            <a
              target="_blank"
              href="https://app.paraacrypto.com/"
            >
              <Btn>Start Trading</Btn>
            </a>
            <Blue>Buy Crypto with Credit or Debit Card</Blue>
             <small>
                Charts powered by{" "}
                <a href=" https://in.tradingview.com/" target="_blank">
                  Tradingview
                </a>
              </small>
          </Container>
        </div>
        <div className="col-sm-12 col-md-6">
          <ImgDiv>
            <ImgTag src={Img} />
          </ImgDiv>
        </div>
      </div>
    </Main>
  );
};

export default About;
