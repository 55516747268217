// this file is a appbar and Create appBar Design

import { Button, Grid, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect } from "react";
import Logo from "../../assets/logo.png";
import MLogo from "../../assets/Mlogo.png";
import Search from "../../assets/icon/Search.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { connetNetwork, connetWallet, setUser } from "../../store/Actions";
import WalletDetails from "../WalletDetails/WalletDetails";
import SwitchNetwork from "../SwitchNetwork";
import Settings from "../Settings";
import CurrencySearch from "../CurrencySearch";
import CustomModal from "../Modal";
import WalletModal from "../WalletModal";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Notification from "../swap/Swap/SwapConfirmModal/Notification";
import WelcomeModel from "../WelcomeModel";
import ErrorModel from "../ErrorModal";
import AccountModal from "../AccountModal";
import DownIconWhite from "../../assets/icon/DownIconWhite.png";
import Pointer from "../../assets/icon/Pointer.png";
import Coin from "../../assets/icon/bsc.png";
import NavIcon from "../../assets/icon/NavIcon.png";
import Cros from "../../assets/icon/Cros.png";
import { useWeb3React } from "@web3-react/core";
import {
    CoinbaseWallet,
    injected,
    WalletConnect,
} from "../../utils/connectors";
import { connectAccount } from "../../service/api";
import { useTheme } from "@emotion/react";
import { Container } from "@mui/system";
// #5e6bfb
const MainComponent = styled("div")({
    display: "flex",
    paddingLeft: 10,
    alignItems: "center",
    justifyContent: "space-around",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    padding: 10,
});
const MDiv = styled("div")({
    display: "flex",
    paddingLeft: 10,
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingBottom: 10,
});
const Sedow = styled("div")({
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});
const ContentDiv = styled("div")({
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#F8F4EC",
});

const InputIcon = styled("div")({
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F7F7F7",
    borderRadius: "16px",
    paddingLeft: "2%",
});

const TextInputActive = styled("input")({
    width: "100%",
    display: "flex",
    backgroundColor: "#F7F7F7",
    borderRadius: "16px",
    padding: "3px",
    borderBottom: 0,
    border: "none",
    height: 52,
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    "&:focus-visible": {
        border: "none",
        outline: "none",
    },
});

const CustomButton = styled(Button)({
    borderRadius: 16,
    backgroundColor: "rgba(0, 0, 0, 0.03)",
    padding: "10px 24px",
    fontSize: "16px",
    fontWeight: "600",
    color: "#000000",
    fontStyle: "Inter",
    opacity: "0.65",
    textTransform: "initial",
    marginLeft: 5,
    marginRight: 5,
    height: 60,
});

const CustomButtonActive = styled(Button)({
  borderRadius: "100px",
  background: "#00B073",
  color: "white",
  // background: "linear-gradient(45deg, #5e6bfb 0%, #5e6bfb75 100%)",
  fontSize: "16px",
  fontWeight: "600",
  // color: "black",
  fontStyle: "Inter",
  textTransform: "initial",
  marginTop: 8,
  height: 40,
  width: "52%",
  alignItems: "center",
  display: "flex",
});

const ButtonConnect = styled("button")({
    borderRadius: "100px",
    backgroundColor: "white",
    padding: "10px 24px",
    fontSize: "16px",
    fontWeight: "600",
    color: "black",
    fontStyle: "Inter",
    textTransform: "initial",
    marginLeft: 5,
    height: 30,
    border: "none",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
});
const Imgs = styled("img")({
    // marginTop: "8%",
    marginLeft:"20px"
});
const CustomButtonActiveCoin = styled(Button)({
    borderRadius: 16,
    background: "red",
    padding: "0px 15px",
    fontSize: "16px",
    fontWeight: "600",
    color: "#FFFFFF",
    fontStyle: "Inter",
    textTransform: "initial",
    marginLeft: 5,
    marginRight: 5,
    "&:hover": {
        backgroundColor: "red",
    },
});
const CustomGrid = styled(Grid)({
    display: "flex",
    justifyContent: "space-evenly",
    // alignItems: 'center',
});

const ControlGrids = styled(Grid)({
    display: "flex",
    justifyContent: "space-evenly",
    paddingLeft: 25,
    paddingRight: 25,
});
const LogoGrid = styled(Grid)({
    marginRight: 0,
});
const Notificationdiv = styled("div")({
    display: "flex",
    justifyContent: "end",
});
const BoxsToken = styled("div")({
    backgroundColor: "#F8F4EC",
    paddingBottom: 30,
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    maxWidth: "585px",
    minHeight: "500px",
    borderRadius: 10,
    position: "relative",
    overflow: "auto",
    "&:hover": {
        overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
        display: "none",
    },
});

const WelcomBoxs = styled("div")({
    backgroundColor: "white",
    width: "500px",
    height: "620px",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: "relative",
});
const ErrorBoxs = styled("div")({
    width: "500px",
    height: "600px",
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#F8F4EC",
    padding: 10,
    border: 'none'
});
const Menu = styled("ul")({
    listStyle: "none",
    marginLeft: "-20px",
    marginRight: "15px",
});
const MenuFlex = styled("ul")({
    listStyle: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: '7%',
    marginTop: 21,
});
const MenuFlex2 = styled("ul")({
    listStyle: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 19,
});
const List = styled("li")(({ theme }) => ({
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "Inter",
    color:theme.palette.primary,
    cursor: "pointer!important",
}));
const MList = styled("li")({
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "Inter",
    padding: 10,
    marginTop: 5,
    "&:hover": {
        backgroundColor: "#edeae5",
    },
});
const CoinDropIcon = styled("img")({
    height: "8.19px",
    width: "8.19px",
    marginLeft: 10,
    marginRight: 5,
});
const ListActive = styled("li")({
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Inter",
    color: "#DAA73B",
});
const CoinBtn = styled("button")({
  // background: "linear-gradient(45deg, #5e6bfb 0%, #5e6bfb75 100%)",
  background: "#00B073",
  color: "white",
  borderRadius: "100px",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: 20,
  width: 165,
  height: 36,
  fontSize: 16,
  fontWeight: 400,
  fontStyle: "Inter",
});
const NavBtn = styled("img")({
    backgroundColor: "transparent",
    padding: 5,
    border: "none",
    marginRight: 10,
    height: 30,
    width: 30,
});
const BtnDiv = styled("div")({
    display: "flex",
});
type DataObject = {
    name: String;
    coin: String;
};
type ConnectWalletType = {
    name: String;
    image: String;
    Subname: String;
    Price: String;
};
type ConnectNetworkType = {
    name: String;
    image: String;
};
const nullObj = {
    name: "",
    coin: "",
};
const settingData = [
    { name: "About" },
    { name: "Help Center" },
    { name: "Request Features" },
    { name: "Language" },
    { name: "Dark Mode" },
];
type AppDispatch = ThunkDispatch<ArticleState, string, AnyAction>;

const AppBar = () => {
    // const { active, activate, deactivate, library, chainId, account } = context;
    const { active, activate, deactivate, library, chainId, account } =
        useWeb3React();
    const theme = useTheme();
    console.log(theme)
    const matches1920 = useMediaQuery("(min-width:1920px)");
    const matches = useMediaQuery("(min-width:1000px)");
    const dispatch: AppDispatch = useDispatch();
    const [ErrorStatus, setErrorStatus] = React.useState(false);
    const [navItem, setNavItem] = React.useState(false);
    const [Check, setCheck] = React.useState<Boolean>(false);
    const [filterInput, setFilterInput] = React.useState<String>("");
    const [WalletData, setWallet] = React.useState<DataObject>(nullObj);
    const [NetworkData, setNetwork] = React.useState<DataObject>(nullObj);
    const [open, setOpen] = React.useState(false);
    const [Welcome, setWelcomeOpen] = React.useState(false);
    const [Error, setErrorOpen] = React.useState(false);
    const [Account, setAccountOpen] = React.useState(false);
    const CoinDetail: ConnectWalletType = useSelector(
        (state: ArticleState) => state.reducer.ConnectWallet
    );
    const CoinNetwork: ConnectNetworkType = useSelector(
        (state: ArticleState) => state.reducer.ConnectNetwork
    );
    const accountBalance: any = useSelector(
        (state: any) => state.user.accountBalance
    );
    console.log("accountBalance: ", accountBalance);

    const openModal = () => {
        setOpen(true);
        // setWelcomeOpen(true);
        // setErrorOpen(true);
        // setAccountOpen(true)
    };
    const closeModel = () => {
        setOpen(false);
    };

    const closeWelcomeModel = () => {
        setWelcomeOpen(false);
    };

    const closeErrorModel = () => {
        setErrorOpen(false);
    };

    const closeAccountModel = () => {
        setAccountOpen(false);
    };

    const handleOpenError = () => setErrorStatus(true);
    const handleCloseError = () => setErrorStatus(false);

    const connetWalletData = (coin: any) => {
        dispatch(connetWallet(coin));
    };
    const connetNetworkData = (coin: any) => {
        dispatch(connetNetwork(coin));
    };
    const connetWalletFunction = (value: DataObject) => {
        setWallet(value);
    };
    const connetNetworkFunction = (value: DataObject) => {
        console.log("value: ", value);
        setNetwork(value);
    };

    const Network = () => {
        // if (WalletData.name !== '' && NetworkData.name !== '') {
        connetWalletData(WalletData);
        connetNetworkData(NetworkData);
        // }
    };

    const connectWallet = (data: any) => {
        console.log("data: ", data);
        if (data.name === "MetaMask") {
            activate(injected);
        } else if (data.name === "Coinbase") {
            activate(CoinbaseWallet);
        } else if (data.name === "Wallet Connect") {
            activate(WalletConnect);
        }
        Network();
    };
    const getProfile = () => {
        connectAccount({ address: account }).then((res) => {
            console.log("res: ", res);
            if (res.status) {
                dispatch(setUser({ user: res?.data?.data, token: res.data?.token }));
            }
        });
    };
    useEffect(() => {
        getProfile();
        closeModel();
    }, [account]);
    console.log("account: ", account);
    return (
      <>
        {matches ? (
          <MainComponent
            style={{
              paddingLeft: matches1920 ? 200 : 0,
              paddingRight: matches1920 ? 200 : 0,
            }}
          >
            <ControlGrids container spacing={0}>
              <LogoGrid item sm={1}>
                <img
                  src={MLogo}
                  height="auto"
                  width="100px"
                  alt="logo"
                />

                {/* <Imgs src={Logo} height="65px" width="150px" alt="logo" /> */}
              </LogoGrid>
              <Grid item sm={3}>
                <MenuFlex>
                  {/* <ListActive>Products</ListActive>
                  <List>App Features</List> */}
                  <List
                    onClick={() => {
                      window.open("https://app.paraacrypto.com/spotBuy", "_blank");
                    }}
                  >
                    Trade
                  </List>
                  <List
                    onClick={() => {
                      window.open("https://app.paraacrypto.com/", "_blank");
                    }}
                  >
                    Market
                  </List>
                  {/* <List>NFT MarketPlace</List> */}
                  {/* <List
                    onClick={() => {
                      window.open("https://app.paraacrypto.com/", "_blank");
                    }}
                  >
                    Earn
                  </List> */}
                </MenuFlex>
                {filterInput !== "" ? (
                  <CurrencySearch searchValue={null} />
                ) : null}
              </Grid>
              {/* <Grid item sm={1}></Grid> */}

              <CustomGrid item sm={3}>
                {/* <MenuFlex2>
                  <List>BSC</List>
                </MenuFlex2> */}
                <Grid item sm={2}></Grid>

                {account ? null : (
                  <CustomButtonActive
                    onClick={() => {
                      window.open("https://app.paraacrypto.com/login", "_blank");
                    }}
                    // onClick={() => {
                    // connetWalletFunction(nullObj);
                    // connetNetworkFunction(nullObj);
                    // setCheck(false);
                    // openModal();
                    // }}
                  >
                    Login
                  </CustomButtonActive>
                )}
                {CoinDetail.name === "" && CoinNetwork.name !== "" ? (
                  <CustomButtonActiveCoin>Wrong Network</CustomButtonActiveCoin>
                ) : !account ? (
                  ""
                ) : (
                  <WalletDetails />
                )}
                {/* <Settings settings={settingData} /> */}
              </CustomGrid>
            </ControlGrids>
          </MainComponent>
        ) : (
          <Sedow>
            <Container>
              <MDiv>
                <img src={MLogo} height="35.5px" width="40px" alt="logo" />
                <BtnDiv>
                  {/* <CoinBtn onClick={() => {
                                connetWalletFunction(nullObj);
                                connetNetworkFunction(nullObj);
                                setCheck(false);
                                openModal();
                            }}>
                                <img src={Coin} height="25px" width="25px" alt="Coin" />
                                &nbsp; 0.0158 ETH
                                <CoinDropIcon src={DownIconWhite} alt="DownArrow" />
                            </CoinBtn> */}
                  {account ? null : (
                    <CoinBtn
                      // onClick={() => {
                      //   connetWalletFunction(nullObj);
                      //   connetNetworkFunction(nullObj);
                      //   setCheck(false);
                      //   openModal();
                      // }}
                      onClick={() => {
                        window.open("https://app.paraacrypto.com/", "_blank");
                      }}
                    >
                      Login{" "}
                    </CoinBtn>
                  )}
                  {CoinDetail.name === "" && CoinNetwork.name !== "" ? (
                    <CustomButtonActiveCoin>
                      Wrong Network
                    </CustomButtonActiveCoin>
                  ) : !account ? (
                    ""
                  ) : (
                    <WalletDetails />
                  )}
                  <NavBtn
                    src={navItem ? Cros : NavIcon}
                    onClick={() => {
                      setNavItem(!navItem);
                    }}
                  />
                </BtnDiv>
              </MDiv>
            </Container>
            {navItem ? (
              <ContentDiv>
                <Menu>
                  {/* <MList>Products</MList>
                  <MList>App Features</MList> */}
                  <MList>Trade</MList>
                  <MList>Swap</MList>
                  {/* <MList>NFT MarketPlace</MList> */}
                  <MList>Earn</MList>
                </Menu>
              </ContentDiv>
            ) : null}
          </Sedow>
        )}

        <CustomModal
          children={
            <BoxsToken>
              <WalletModal
                setCheck={(val: any) => {
                  setCheck(val);
                }}
                SelectData={(data: any) => {
                  console.log("data: ", data);
                  connectWallet(data);
                }}
                Network={() => {
                  Network();
                }}
                Check={Check}
                WalletData={WalletData}
                ErrorStatus={ErrorStatus}
                handleCloseError={() => {
                  handleCloseError();
                }}
                handleOpenError={() => {
                  handleOpenError();
                }}
                connetNetworkFunction={(val: any) => {
                  connetNetworkFunction(val);
                }}
                connetWalletFunction={(val: any) => {
                  connetWalletFunction(val);
                }}
                NetworkData={NetworkData}
                onClose={() => {
                  closeModel();
                }}
              />
            </BoxsToken>
          }
          isOpen={open}
          modalTitle="WalletModal"
          close={() => {
            closeModel();
          }}
        />
        <CustomModal
          children={
            <WelcomBoxs>
              <WelcomeModel
                onClose={() => {
                  closeWelcomeModel();
                }}
              />
            </WelcomBoxs>
          }
          isOpen={Welcome}
          modalTitle="WalletModal"
          close={() => {
            closeWelcomeModel();
          }}
        />
        <CustomModal
          children={
            <ErrorBoxs>
              <ErrorModel
                onClose={() => {
                  closeErrorModel();
                }}
              />
            </ErrorBoxs>
          }
          isOpen={Error}
          modalTitle="WalletModal"
          close={() => {
            closeErrorModel();
          }}
        />
        <CustomModal
          children={
            <ErrorBoxs>
              <AccountModal
                onClose={() => {
                  closeAccountModel();
                }}
              />
            </ErrorBoxs>
          }
          isOpen={Account}
          modalTitle="WalletModal"
          close={() => {
            closeAccountModel();
          }}
        />
        <Notificationdiv>
          <Notification />
        </Notificationdiv>
      </>
    );
};

export default AppBar;
